import React, { Fragment, useEffect } from 'react';
import WinsList from './wins/WinsList';
import useWinsApi from '../hooks/useWinsApi';

function Wins() {
    const { state, fetchWins, deleteWin } = useWinsApi();
    const { data, isLoading, isError } = state;

    useEffect(() => {
        const fetchData = async () => {
            fetchWins();
        }

        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="py-4">
                    { isLoading && (
                        <div className="w-full h-64 flex items-center justify-center">
                            <svg className="w-8 h-8 text-gray-200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(3.8934 50 50)">
                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                            </svg>
                        </div>
                    )}
                    { isError && (
                        <p>Error while fetching data</p>
                    )}
                    { (data.length > 0 && ! isLoading && ! isError) && (
                        <WinsList items={ data } onDelete={ deleteWin } title="Your Wins"></WinsList>
                    )}
                    { (data.length === 0 && ! isLoading && ! isError) && (
                        <div className="mt-4 text-gray-500 text-sm leading-5">You don't have any wins entered yet!</div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

export default Wins;