import { useReducer, useContext } from 'react';
import UserContext from '../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
                error: null
            }
        case 'LOGIN_ERROR':
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                error: null
            }
            case 'LOGOUT_INIT':
                return {
                    ...state,
                    isLoading: true,
                    isError: false,
                    error: null
                }
            case 'LOGOUT_ERROR':
                return {
                    ...state,
                    isLoading: false,
                    isError: true,
                    error: action.payload
                }
            case 'LOGOUT_SUCCESS':
                return {
                    ...state,
                    isLoading: false,
                    isError: false,
                    error: null
                }
        default:
            throw new Error(`Invalid reducer action: ${action.type}`);
    }
};

const hasCsrfToken = () => {
    return getCookie('XSRF-TOKEN') !== null;
};

const clearCsrfToken = () => {
    eraseCookie('XSRF-TOKEN');
}

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }

    return null;
}

const eraseCookie = (name) => {
    document.cookie = name+'=; Max-Age=-99999999;';
}

const useAuthApi = () => {
    const [state, dispatch] = useReducer(authReducer, {
        isLoading: false,
        isError: false,
        error: null
    });

    const history = useHistory();

    // eslint-disable-next-line
    const { user, setUser } = useContext(UserContext);

    const login = async (email, password, rememberMe) => {
        dispatch({ type: 'LOGIN_INIT' });

        try {
            await axios.get('/sanctum/csrf-cookie');
            const result = await axios.post('/login', {
                'email': email,
                'password': password,
                'remember': rememberMe ? '1' : null,
            });

            dispatch({ type: 'LOGIN_SUCCESS' });

            setUser(result.data.data);
            localStorage.setItem('user', JSON.stringify(result.data.data));

            history.push('/dashboard');
        } catch (error) {
            console.error(error);
            dispatch({ type: 'LOGIN_ERROR', payload: error.response.data.errors });
        }
    }

    const logout = async () => {
        dispatch({ type: 'LOGOUT_INIT' });

        try {
            await axios.post('/logout');

            dispatch({ type: 'LOGOUT_SUCCESS' });

            setUser(null);
            localStorage.removeItem('user');

            history.push('/');
        } catch (error) {
            console.error(error);
            dispatch({ type: 'LOGOUT_ERROR', payload: error.response.data.errors });
        }
    }

    return { state, login, logout };
};

export default useAuthApi;

export { hasCsrfToken, clearCsrfToken };