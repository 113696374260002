import React, { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';

const WinDropdown = (props) => {
    const { options } = props;

    const ref = useRef();

    const [open, toggleDropdown] = useState(false);

    useOnClickOutside(ref,() => toggleDropdown(false));

    return (
        <div className="relative inline-block">
            <div>
                <button type="button" onClick={() => toggleDropdown(() => { return ! open; })} className="inline-flex justify-center w-full px-4 py-2 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-none focus:shadow-outline-none active:text-gray-800 transition ease-in-out duration-150">
                    <svg fill="currentColor" viewBox="0 0 20 20" className="w-4 h-4"><path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg>
                </button>
            </div>
            { open && (
                <div ref={ ref } className="origin-top-left absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10">
                    <div className="rounded-md bg-white shadow-xs">
                        <div className="py-1">
                            { options.map((option) => <a key={ option.label } href="#select-option" onClick={(e) => { e.preventDefault(); option.action(); toggleDropdown(false); }} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">{ option.label }</a>)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WinDropdown;