import React from 'react';
import PageWithSidebar from '../components/PageWithSidebar';

function NotYetImplementedPage() {
    return (
        <PageWithSidebar>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <p className="text-gray-400">Page has not been implemented yet. Stay tuned!</p>
            </div>
        </PageWithSidebar>
    );
}

export default NotYetImplementedPage;