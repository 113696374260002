import React, { useState } from 'react';
import Sidebar from '../partials/Sidebar';
import SidebarContext from '../contexts/SidebarContext';
import { Link } from "react-router-dom";

function PageWithSidebar(props) {
    const [open, setOpen] = useState(false);

    const toggleSidebar = (openSidebar) => setOpen(() => openSidebar);

    return (
        <SidebarContext.Provider value={{ open, toggleSidebar }}>
            <div className="h-screen flex overflow-hidden bg-gray-100">
                <Sidebar></Sidebar>
                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                    <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 flex justify-between items-center">
                        <button onClick={ () => toggleSidebar(true) } className="-ml-1 -mt-1 h-10 w-10 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                        </button>
                        <Link to="/dashboard" className="flex-shrink-0 flex-1 flex items-center justify-center px-4 font-bold">
                            <span role="img" aria-label="Trophy">🏆</span> Tiny Wins
                        </Link>
                        <div className="w-10"></div>
                    </div>
                    <main className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6" tabIndex="0">
                        { props.children }
                    </main>
                </div>
            </div>
        </SidebarContext.Provider>
    );
}

export default PageWithSidebar;