import React, { useRef, useContext, useState } from 'react';
import { Link } from "react-router-dom";

import useProfileApi from '../hooks/useProfileApi';
import UserContext from '../contexts/UserContext';

function Error(props) {
    if ( ! props.errors.any() || ! props.errors.has(props.for)) {
        return null;
    }

    return (
        <p className="mt-2 text-sm text-red-600 text-left">{ props.errors.first(props.for) }</p>
    )
}

function SharingOption(props) {
    return (
        <>
            <input id={ props.value } name="sharing" type="radio" className="form-radio h-4 w-4 text-yellow-600 transition duration-150 ease-in-out" value={ props.value } checked={ props.checked === props.value } onChange={ () => props.setChecked(props.value) }/>
            <label htmlFor={ props.value } className="ml-3">
                <span className="block text-sm leading-5 font-medium text-gray-500">{ props.children }</span>
            </label>
        </>
    );
}

function ProfileForm(props) {
    const { user } = useContext(UserContext);
    const nicknameInput = useRef(user.nickname);
    const nameInput = useRef(user.name);
    const [sharing, setSharing] = useState(user.sharing);

    const { state, updateProfile } = useProfileApi();
    const { isUpdating, errors } = state;

    const save = (event) => {
        event.preventDefault();

        updateProfile({
            'name': nameInput.current.value,
            'nickname': nicknameInput.current.value,
            sharing,
        });
    }

    return (
        <form className="mt-4 p-8" action="#" method="POST" onSubmit={ save }>
            <div className="mb-8">
                <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                    <input id="email" className="flex-1 form-input block w-full min-w-0 rounded-none rounded-md border-gray-300 bg-gray-50 text-gray-500" readOnly value={ user.email } />
                </div>
            </div>
            <div className="mb-8">
                <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Name</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                    <input ref={ nameInput } id="name" className="flex-1 form-input block w-full min-w-0 rounded-none rounded-md" defaultValue={ nameInput.current } />
                </div>
                <Error for="name" errors={ errors } />
            </div>
            <div className="mb-8">
                <label htmlFor="nickname" className="block text-sm font-medium leading-5 text-gray-700">Nickname</label>
                <div className="mt-1 flex rounded-md shadow-sm">
                    <input ref={ nicknameInput } id="name" className="flex-1 form-input block w-full min-w-0 rounded-none rounded-md" defaultValue={ nicknameInput.current } />
                </div>
                <Error for="nickname" errors={ errors }  />
            </div>
            <div className="mb-8">
                <fieldset>
                    <legend className="text-sm font-medium leading-5 text-gray-700">Sharing Settings</legend>
                    <div className="mt-4">
                        <div className="flex items-center">
                            <SharingOption value="public" checked={ sharing } setChecked={ setSharing }>Share my wins publicly</SharingOption>
                        </div>
                        <div className="mt-2 flex items-center">
                            <SharingOption value="anonymous" checked={ sharing } setChecked={ setSharing }>Share my wins but do so anonymously (do not show my name)</SharingOption>
                        </div>
                        <div className="mt-2 flex items-center">
                            <SharingOption value="private" checked={ sharing } setChecked={ setSharing }>Do not share my wins with anybody</SharingOption>
                        </div>
                    </div>
                    <Error for="sharing" errors={ errors }  />
                </fieldset>
            </div>

            <div className="border-t border-gray-200 pt-5">
                <div className="flex justify-end">
                    <span className="inline-flex rounded-md shadow-sm">
                        <Link to="/dashboard" className="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">Cancel</Link>
                    </span>
                    <span className="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                            type="submit"
                            disabled={ isUpdating }
                            className={`inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-yellow-500 ${isUpdating ? 'cursor-not-allowed' : 'hover:bg-yellow-400 focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600'} transition ease-in-out duration-150`}
                        >
                            { isUpdating ? (
                                <svg className="w-5 h-5 text-white" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(3.8934 50 50)">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                    </circle>
                                </svg>
                            ) : 'Save' }
                        </button>
                    </span>
                </div>
            </div>
        </form>
    );
}

export default ProfileForm;