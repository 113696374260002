import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import fetchQuotes from '../selectors/quotes';

function BlockQuote(props) {
    const { author, quote } = props;

    return (
        <blockquote className="mb-4">
            <p className="text-gray-500">"{ quote }"</p>
            <footer className="text-xs text-yellow-400">- { author }</footer>
        </blockquote>
    );
}

function QuotesList() {
    const data = useRecoilValue(fetchQuotes);

    if ( ! data.length) {
        return null;
    }

    return (
        <div className="bg-white shadow sm:rounded-md mt-4">
            <div className="px-4 py-4 sm:px-6">
                { data.map((item) => {
                    return (
                        <BlockQuote author={ item.author } quote={ item.quote } key={ item.id }></BlockQuote>
                    );
                })}
            </div>
        </div>
    );
}

function LoadingQuotes() {
    return (
        <div className="w-full h-64 flex items-center justify-center">
            <svg className="w-8 h-8 text-gray-200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(3.8934 50 50)">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
            </svg>
        </div>
    );
}

function Quotes() {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h2 className="text-2xl text-gray-400">Inspirations</h2>
            <div className="py-4">
                <Suspense fallback={ <LoadingQuotes /> }>
                    <QuotesList />
                </Suspense>
            </div>
        </div>
    );
}

export default Quotes;