import React, { useRef } from 'react';
import useAuthApi from '../hooks/useAuthApi';

function LoginPage() {
    const { state, login } = useAuthApi();

    const emailInput = useRef();
    const passwordInput = useRef();
    const rememberMeInput = useRef();

    const { error, isLoading, isError } = state;

    const doLogin = (event) => {
        event.preventDefault();

        login(emailInput.current.value, passwordInput.current.value, rememberMeInput.current.checked);
    }

    return (
        <div className="relative bg-gray-200">
            <div className="mx-auto max-w-screen-xl px-4 py-12 min-h-screen flex justify-center items-center">
                <div className="text-center">
                    <h1 className="text-6xl tracking-tight font-bold mb-8"><span role="img" aria-label="Trophy">🏆</span> Tiny Wins</h1>
                    <h2 className="text-4xl tracking-tight leading-10 font-semibold text-gray-900">Sign in to your account</h2>

                    <form className="mt-8" action="#" method="POST" onSubmit={ doLogin }>
                        <div className="rounded-md shadow-sm">
                            <div>
                                <input ref={ emailInput } aria-label="Email address" name="email" type="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Email address" />
                            </div>
                            <div className="-mt-px">
                                <input ref={ passwordInput } aria-label="Password" name="password" type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Password" />
                            </div>
                        </div>

                        { isError && (
                            <p className="mt-2 text-sm text-red-600 text-left" id="email-error">{ error.email[0] }</p>
                        )}

                        <div className="mt-6 flex items-center justify-between">
                            <div className="flex items-center">
                                <input ref={ rememberMeInput } id="remember" type="checkbox" className="form-checkbox h-4 w-4 text-yellow-600 transition duration-150 ease-in-out" />
                                <label htmlFor="remember" className="ml-2 block text-sm leading-5 text-gray-900">
                                    Remember me
                                </label>
                            </div>

                            <div className="text-sm leading-5">
                                <a href="#forgotten-password" className="font-medium text-yellow-500 hover:text-yellow-400 focus:outline-none focus:underline transition ease-in-out duration-150">
                                    Forgot your password?
                                </a>
                            </div>
                        </div>

                        <div className="mt-6">
                            <button disabled={ isLoading } type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700 transition duration-150 ease-in-out">
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <svg className="h-5 w-5 text-yellow-400 group-hover:text-yellow-500 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;