import React, { Fragment } from 'react';
import WinItem from './WinItem';

function WinDateGroup(props) {
    const { date, group, onDelete } = props;

    return (
        <li>
            <div className="block">
                <div className="px-4 pt-4 sm:px-6">
                    <div className="text-sm leading-5 font-medium text-yellow-500 truncate">
                        <time dateTime={ date }>{ date }</time>
                    </div>
                    { group.map((win) => <WinItem win={ win } key={ win.id } onDelete={ onDelete }></WinItem>) }
                </div>
            </div>
        </li>
    );
}

function WinsList(props) {
    const { items, onDelete, title } = props;

    const wins = {};

    items.forEach(win => {
        // Init empty array if date key does not exist on the collection
        if ( ! wins.hasOwnProperty(win.date)) {
            wins[win.date] = [];
        }

        wins[win.date].push(win);
    });

    let winKeys = Object.keys(wins);
    winKeys.sort().reverse();

    const hasWins = winKeys.length;

    return (
        <div className={ props.className }>
            <h2 className="text-yellow-500 font-bold">{ title }</h2>

            { hasWins && (
                <Fragment>
                    <div className="bg-white shadow sm:rounded-md mt-4">
                        <ul>
                            { winKeys.map((date, index) => <WinDateGroup date={ date } index={ index } group={ wins[date] } key={ index } onDelete={ onDelete }></WinDateGroup>) }
                        </ul>
                    </div>
                    { props.children }
                </Fragment>
            )}
        </div>
    );
}

export default WinsList;