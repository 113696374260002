import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Wins from '../components/Wins';
import UserContext from '../contexts/UserContext';
import PageWithSidebar from '../components/PageWithSidebar';

function DashboardPage() {
    const { user } = useContext(UserContext);
    const history = useHistory();

    if ( ! user) {
        history.push('/login');
    }

    return (
        <PageWithSidebar>
            <Wins></Wins>
        </PageWithSidebar>
    );
}

export default DashboardPage;