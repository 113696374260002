class Errors {
    constructor(errors) {
        this.errors = errors;
    }

    clear() {
        this.errors = null;
    }

    any() {
        return this.errors !== null;
    }

    has(key) {
        return this.errors !== null && (key in this.errors);
    }

    first(key) {
        console.log(key, this.errors[key])
        return this.errors[key][0];
    }

    get(key) {
        return this.errors[key];
    }
}

export default Errors;