import { useReducer } from 'react';
import axios from 'axios';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const signupReducer = (state, action) => {
    switch (action.type) {
        case 'SIGNUP_INIT':
            return {
                isLoading: true,
                isError: false,
                didSignup: false,
            }
        case 'SIGNUP_ERROR':
            return {
                isLoading: false,
                isError: true,
                didSignup: false,
            }
        case 'SIGNUP_SUCCESS':
            return {
                isLoading: false,
                isError: false,
                didSignup: true,
            }
        default:
            throw new Error(`Invalid reducer action: ${action.type}`);
    }
}

const useSignupApi = () => {
    const [state, dispatch] = useReducer(signupReducer, {
        isLoading: false,
        isError: false,
        didSignup: false,
    });

    const signup = async (email) => {
        dispatch({ type: 'SIGNUP_INIT' });

        try {
            await axios.post('/', encode({
                'email': email,
                'form-name': 'signup'
            }), {
                baseURL: null,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });

            dispatch({ type: 'SIGNUP_SUCCESS' });
        } catch (error) {
            console.error(error);

            dispatch({ type: 'SIGNUP_ERROR' });
        }
    }

    return { state, signup };
}

export default useSignupApi;