import React, { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';

function DateDropdown(props) {
    const { value, options, onChange } = props;

    const ref = useRef();

    const [open, toggleDropdown] = useState(false);

    useOnClickOutside(ref, () => toggleDropdown(false));

    return (
        <div className="relative sm:inline-block text-left mb-4 sm:mb-0">
            <div>
                <button type="button" onClick={() => toggleDropdown(() => { return ! open; })} className="inline-flex justify-between sm:justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-gray-200 text-sm leading-5 font-medium shadow-sm text-gray-700 hover:text-gray-500 focus:outline-none focus:border-yellow-300 focus:shadow-outline-yellow active:bg-gray-300 active:text-gray-800 transition ease-in-out duration-150">
                    { value }
                    <svg className="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"/>
                    </svg>
                </button>
            </div>
            { open && (
                <div ref={ ref } className="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg z-10">
                    <div className="rounded-md bg-white shadow-xs">
                        <div className="py-1">
                            { options.map((option) => <a key={ option } href="#select-option" onClick={(e) => { e.preventDefault(); onChange(option); toggleDropdown(false); }} className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">{ option }</a>)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DateDropdown;
