import React, { useState, useMemo } from 'react';
import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import UserContext from './contexts/UserContext';

import LoginPage from './pages/LoginPage';
import QuotesPage from './pages/QuotesPage';
import SignupPage from './pages/SignupPage';
import LandingPage from './pages/LandingPage';
import AllWinsPage from './pages/AllWinsPage';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import NotYetImplementedPage from './pages/NotYetImplementedPage';
import { RecoilRoot } from 'recoil';
import LoginErrorBoundary from './components/LoginErrorBoundary';

function App() {
    const localStorageUser = localStorage.getItem('user');
    const [user, setUser] = useState(localStorageUser ? JSON.parse(localStorageUser) : null);
    const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);

    return (
        <RecoilRoot>
            <UserContext.Provider value={ providerValue }>
                <Router>
                    <LoginErrorBoundary>
                        <Switch>
                            <Route path="/login">
                                <LoginPage></LoginPage>
                            </Route>
                            <Route path="/signup">
                                <SignupPage></SignupPage>
                            </Route>
                            <Route path="/dashboard">
                                <DashboardPage></DashboardPage>
                            </Route>
                            <Route path="/inspirations">
                                <QuotesPage></QuotesPage>
                            </Route>
                            <Route path="/all">
                                <AllWinsPage></AllWinsPage>
                            </Route>
                            <Route path="/profile">
                                <ProfilePage></ProfilePage>
                            </Route>
                            <Route path="/" exact>
                                <LandingPage></LandingPage>
                            </Route>
                            <Route>
                                <NotYetImplementedPage></NotYetImplementedPage>
                            </Route>
                        </Switch>
                    </LoginErrorBoundary>
                </Router>
            </UserContext.Provider>
        </RecoilRoot>
  );
}

export default App;
