import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import AllWins from '../components/AllWins';
import UserContext from '../contexts/UserContext';
import PageWithSidebar from '../components/PageWithSidebar';

function AllWinsPage() {
    const { user } = useContext(UserContext);
    const history = useHistory();

    if ( ! user) {
        history.push('/login');
    }

    return (
        <PageWithSidebar>
            <AllWins></AllWins>
        </PageWithSidebar>
    );
}

export default AllWinsPage;