import { selector } from 'recoil';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

const fetchQuotes = selector({
    key: 'quotesSelector',
    get: async ({ get }) => {
        const result = await axios.get('/api/quotes');

        return result.data.data;
    }
});

export default fetchQuotes;