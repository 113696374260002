import React, { Fragment, useEffect } from 'react';
import { Link } from "react-router-dom";
import NewWinForm from './wins/NewWinForm';
import WinsList from './wins/WinsList';
import useWinsApi from '../hooks/useWinsApi';
import InspirationsList from './wins/InspirationsList';

function Wins() {
    const { state, fetchLatestWins, addWin, deleteWin } = useWinsApi();
    const { data, isLoading, isError, isSaving, saveError } = state;

    useEffect(() => {
        const fetchData = async () => {
            fetchLatestWins();
        }

        fetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <NewWinForm add={ addWin } error={ saveError } isPending={ isSaving }></NewWinForm>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="py-4">
                    { isLoading && (
                        <div className="w-full h-64 flex items-center justify-center">
                            <svg className="w-8 h-8 text-gray-200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(3.8934 50 50)">
                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                            </svg>
                        </div>
                    )}
                    { isError && (
                        <p>Error while fetching data</p>
                    )}
                    { (data.length > 0 && ! isLoading && ! isError) && (
                        <div className="block sm:flex md:block lg:flex">
                            <WinsList items={ data } onDelete={ deleteWin } title="Your Latest Wins" className="w-full sm:w-1/2 md:w-full lg:w-1/2 mr-0 sm:mr-2 md:mr-0 lg:mr-2">
                                <div className="flex items-center justify-center mt-4">
                                    <Link to="/all" className="px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-yellow-500 bg-white hover:text-yellow-400 focus:outline-none focus:shadow-outline-yellow transition duration-150 ease-in-out">See all your wins</Link>
                                </div>
                            </WinsList>
                            <InspirationsList></InspirationsList>
                        </div>
                    )}
                    { (data.length === 0 && ! isLoading && ! isError) && (
                        <div className="mt-4 text-gray-500 text-sm leading-5">Start adding your wins!</div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

export default Wins;