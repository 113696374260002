import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Quotes from '../components/Quotes';
import UserContext from '../contexts/UserContext';
import PageWithSidebar from '../components/PageWithSidebar';

function QuotesPage() {
    const { user } = useContext(UserContext);
    const history = useHistory();

    if ( ! user) {
        history.push('/login');
    }

    return (
        <PageWithSidebar>
            <Quotes></Quotes>
        </PageWithSidebar>
    );
}

export default QuotesPage;