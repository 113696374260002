import React, { Suspense } from 'react';

import { useRecoilValue } from 'recoil';
import fetchLatestInspirations from '../../selectors/inspirations';

function LoadingInspirations() {
    return (
        <div className="w-full h-64 flex items-center justify-center">
            <svg className="w-8 h-8 text-gray-200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(3.8934 50 50)">
                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
            </svg>
        </div>
    );
}

function WinDateGroup(props) {
    const { date, group } = props;

    return (
        <li>
            <div className="block">
                <div className="px-4 pt-4 sm:px-6">
                    <div className="text-sm leading-5 font-medium text-yellow-500 truncate">
                        <time dateTime={ date }>{ date }</time>
                    </div>
                    { group.map((win) => <WinItem win={ win } key={ win.id }></WinItem>) }
                </div>
            </div>
        </li>
    );
}

function WinItem(props) {
    const { win } = props;

    return (
        <div className="-mx-4 sm:-mx-6 px-4 sm:px-6 py-2 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
            <div className="text-sm leading-5 text-gray-500">
                { win.text }
            </div>
            <div className="text-xs leading-5 text-gray-400">
                by <span className="text-gray-500">{ win.user_nickname }</span>
            </div>
        </div>
    )
}

function InspirationsList(props) {
    const items = useRecoilValue(fetchLatestInspirations);

    const wins = {};

    items.forEach(win => {
        // Init empty array if date key does not exist on the collection
        if ( ! wins.hasOwnProperty(win.date)) {
            wins[win.date] = [];
        }

        wins[win.date].push(win);
    });

    let winKeys = Object.keys(wins);
    winKeys.sort().reverse();

    const hasWins = winKeys.length;

    return (
        <Suspense fallback={ LoadingInspirations }>
            <div className="w-full sm:w-1/2 md:w-full lg:w-1/2 mt-4 sm:mt-0 md:mt-4 lg:mt-0 ml-0 sm:ml-2 md:ml-0 lg:ml-2">
                <h2 className="text-yellow-500 font-bold">Inspirational Wins</h2>
                <div className="bg-white shadow sm:rounded-md mt-4">
                    { hasWins ? (
                        <ul>
                            { winKeys.map((date, index) => <WinDateGroup date={ date } index={ index } group={ wins[date] } key={ index }></WinDateGroup>) }
                        </ul>
                    ) : <p className="text-sm leading-5 text-gray-500 px-4 py-4 sm:px-6">There are no shared wins yet. Be among the first to share your by adjusting "sharing" preference in your profile.</p>}
                </div>
            </div>
        </Suspense>
    );
}

export default InspirationsList;