import React, { Fragment, useRef, useState } from 'react';
import DateDropdown from '../DateDropdown';
import { today, yesterday } from '../../helpers/date';
import confetti from 'canvas-confetti';

function NewWinForm(props) {
    const { add, isPending, error } = props;

    const [date, setDate] = useState("Today");

    const textInput = useRef();

    const fireConfetti = () => {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: {
                y: 0.6
            }
          });
    }

    const reset = () => {
        textInput.current.value = "";
        setDate("Today");
    };

    const parseDate = (dateString) => {
        if (dateString === "Today") {
            return today();
        } else if (dateString === "Yesterday") {
            return yesterday();
        }
    }

    return (
        <Fragment>
            <p className="text-gray-400 text-sm pb-2">Tell us what did you acomplish recently. Remember, no feat is too small!</p>
            <div className="sm:flex items-top">
                <DateDropdown value={ date } options={ ['Today', 'Yesterday'] } onChange={(date) => setDate(date)}></DateDropdown>
                <div className="flex-1 sm:ml-2 mb-4 sm:mb-0">
                    <div className="relative rounded-md shadow-sm">
                        <input ref={ textInput } id="text" className="form-input bg-gray-200 block w-full sm:text-sm sm:leading-5 focus:border-yellow-300 focus:shadow-outline-yellow text-sm leading-5" autoComplete="off" placeholder="I swam 2k under an hour" />
                    </div>

                    { error && (
                        <p className="mt-2 text-sm text-red-600 text-left" id="text-error">{ error.text[0] }</p>
                    )}
                </div>
                <div className="sm:ml-2 sm:w-20 mb-4 sm:mb-0">
                    <span className="inline-flex w-full rounded-md shadow-sm">
                        <button
                            type="button"
                            disabled={ isPending }
                            onClick={() => { add({ id: Date.now(), text: textInput.current.value, date: parseDate(date) }); fireConfetti(); reset() }}
                            className={`w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-yellow-500 ${isPending ? 'cursor-not-allowed' : 'hover:bg-yellow-400 focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600'} transition ease-in-out duration-150`}
                        >
                            { isPending ? (
                                <svg className="w-5 h-5 text-white" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(3.8934 50 50)">
                                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                    </circle>
                                </svg>
                            ) : 'Save' }
                        </button>
                    </span>
                </div>
            </div>
        </Fragment>
    );
}

export default NewWinForm;