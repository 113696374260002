import React, { Fragment, useContext, Suspense } from 'react';
import UserContext from '../contexts/UserContext';
import SidebarContext from '../contexts/SidebarContext';
import useAuthApi from '../hooks/useAuthApi';
import { Link, NavLink } from "react-router-dom";
import fetchStreak from '../selectors/streak';
import { useRecoilValue } from 'recoil';

function UserProfileButton(props) {
    const { user } = props;

    if (user === null) {
        return null;
    }

    return (
        <Link to="/profile" className="flex-shrink-0 group block focus:outline-none">
            <div className="flex items-center">
                <div>
                    <img className="inline-block h-10 w-10 rounded-full" src="https://s.gravatar.com/avatar/60a1aaf6f5c16fef59343d47c0ec8b76?s=80&r=g" alt="" />
                </div>
                <div className="ml-3">
                    <p className="text-base leading-6 font-medium text-gray-700 group-hover:text-gray-900">
                        { user.name }
                    </p>
                    <p className="text-sm leading-5 font-medium text-gray-500 group-hover:text-gray-700 group-focus:underline transition ease-in-out duration-150">
                        View profile
                    </p>
                </div>
            </div>
        </Link>
    );
}

function UserStreak() {
    const streak = useRecoilValue(fetchStreak);

    return (
        <div className="w-full flex items-center justify-between">
            <div className="w-full flex flex-col items-center">
                <div className="text-xs text-gray-400 uppercase">Current</div>
                <div className="flex items-center">
                    <svg className="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                    <span className="text-gray-500">{ streak.current }</span>
                </div>
            </div>

            <div className="w-full flex flex-col items-center">
                <div className="text-xs text-gray-400 uppercase">Longest</div>
                <div className="flex items-center">
                    <svg className="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                    <span className="text-gray-500">{ streak.longest }</span>
                </div>
            </div>
        </div>
    );
}

function Sidebar() {
    const { user } = useContext(UserContext);
    const { logout } = useAuthApi();
    const { open, toggleSidebar } = useContext(SidebarContext);

    return (
        <Fragment>
            { open && (
                <div className="md:hidden">
                    <div className="fixed inset-0 z-30 transition-opacity ease-linear duration-300">
                        <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                    </div>
                    <div className="fixed inset-0 flex z-40">
                        <div className="flex-1 flex flex-col max-w-xs w-full bg-white transform ease-in-out duration-300 ">
                            <div className="absolute top-0 right-0 -mr-12 p-1">
                                <button onClick={ () => toggleSidebar(false) } className="flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:bg-gray-600">
                                    <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4 font-bold">
                                    <span role="img" aria-label="Trophy">🏆</span> Tiny Wins
                                </div>
                                <nav className="mt-5 px-2">
                                    <NavLink
                                        to="/dashboard"
                                        className="group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
                                        activeClassName="text-gray-900 bg-gray-100"
                                    >
                                        <svg className="mr-4 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"/>
                                        </svg>
                                        Dashboard
                                    </NavLink>
                                    <NavLink
                                        to="/inspirations"
                                        className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
                                        activeClassName="text-gray-900 bg-gray-100"
                                    >
                                        <svg className="mr-4 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                                        </svg>
                                        Inspirations
                                    </NavLink>
                                    { user && (
                                        <div className="border-t border-gray-200 mt-2">
                                            <button onClick={ () => logout() } type="button" className="w-full mt-2 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150">Logout</button>
                                        </div>
                                    )}
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                <Suspense fallback={ <p>loading</p> }>
                                    <UserStreak></UserStreak>
                                </Suspense>
                            </div>
                            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                <UserProfileButton user={ user }></UserProfileButton>
                            </div>
                        </div>
                        <div className="flex-shrink-0 w-12"></div>
                    </div>
                </div>
            )}
            <div className="hidden md:flex md:flex-shrink-0">
                <div className="flex flex-col w-64 border-r border-gray-200 bg-white">
                    <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div className="flex items-center flex-shrink-0 px-4 font-bold">
                            <span role="img" aria-label="Trophy">🏆</span> Tiny Wins
                        </div>
                        <nav className="mt-5 px-2">
                            <NavLink
                                to="/dashboard"
                                className="group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
                                activeClassName="text-gray-900 bg-gray-100"
                            >
                                <svg className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-600 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"/>
                                </svg>
                                Dashboard
                            </NavLink>
                            <NavLink
                                to="/inspirations"
                                className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
                                activeClassName="text-gray-900 bg-gray-100"
                            >
                                <svg className="mr-3 h-6 w-6 text-gray-500 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                                </svg>
                                Inspirations
                            </NavLink>
                            { user && (
                                <div className="border-t border-gray-200 mt-2">
                                    <button onClick={ () => logout() } type="button" className="w-full mt-2 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150">Logout</button>
                                </div>
                            )}
                        </nav>
                    </div>
                    <div className="flex-shrink-0 flex border-t border-gray-200 p-4 bg-gray-100">
                        <Suspense fallback={ <p>loading</p> }>
                            <UserStreak></UserStreak>
                        </Suspense>
                    </div>
                    <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                        <UserProfileButton user={ user }></UserProfileButton>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Sidebar;