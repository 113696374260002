import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { hasCsrfToken, clearCsrfToken } from '../hooks/useAuthApi';
import { useHistory } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import axios from 'axios';

function LandingPage() {
    const history = useHistory();

    // eslint-disable-next-line
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                await axios.get('/sanctum/csrf-cookie');
                const result = await axios.get('/api/user');

                setUser(result.data.data);
                localStorage.setItem('user', JSON.stringify(result.data.data));

                history.push('/dashboard');
            } catch (error) {
                console.error(error);

                clearCsrfToken();
            }
        };

        if (hasCsrfToken()) {
            history.push('/dashboard');
        } else {
            fetchUser();
        }
    }, [history, setUser]);

    return (
        <div className="relative bg-gray-200">
            <div className="mx-auto max-w-screen-xl px-4 py-12 min-h-screen flex justify-center items-center">
                <div className="text-center">
                    <h1 className="text-6xl tracking-tight font-bold mb-8"><span role="img" aria-label="Trophy">🏆</span> Tiny Wins</h1>
                    <h2 className="text-4xl tracking-tight leading-10 font-semibold text-gray-900">
                        Habits are hard. Start small.
                    </h2>
                    <h2 className="text-4xl tracking-tight leading-10 font-semibold text-yellow-500">
                        Or even better start TINY!
                    </h2>
                    <p className="mt-3 max-w-md mx-auto text-base text-gray-500">
                        Idea taken from James Clear Atomic Habits for starting small and leverage compounding.
                    </p>
                    <div className="mt-5 max-w-md mx-auto flex justify-center">
                        <div className="rounded-md shadow">
                            <Link to="/login" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:shadow-outline-yellow transition duration-150 ease-in-out">
                                Log In
                            </Link>
                        </div>
                        <div className="rounded-md shadow ml-3">
                            <Link to="/signup" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-yellow-500 bg-white hover:text-yellow-400 focus:outline-none focus:shadow-outline-yellow transition duration-150 ease-in-out">
                                Sign Up
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;