import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import ProfileForm from '../components/ProfileForm';
import UserContext from '../contexts/UserContext';
import PageWithSidebar from '../components/PageWithSidebar';

function ProfilePage() {
    const { user } = useContext(UserContext);
    const history = useHistory();

    if ( ! user) {
        history.push('/login');
    }

    return (
        <PageWithSidebar>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="py-4">
                    <h2 className="text-yellow-500 font-bold">Your Profile</h2>
                    <div className="bg-white shadow sm:rounded-md mt-4">
                        <ProfileForm></ProfileForm>
                    </div>
                </div>
            </div>
        </PageWithSidebar>
    );
}

export default ProfilePage;