const format = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
}

const today = () => {
    return format(new Date());
}

const yesterday = () => {
    let date = new Date();
    date.setDate(date.getDate() - 1);

    return format(date);
}

export { today, yesterday };