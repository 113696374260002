import { useReducer, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import Errors from '../helpers/errors';
import UserContext from '../contexts/UserContext';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

const profileReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_INIT':
            return {
                isError: false,
                isUpdating: true,
                errors: new Errors(null),
            }
        case 'UPDATE_ERROR':
            return {
                isError: true,
                isUpdating: false,
                errors: new Errors(action.payload),
            }
        case 'UPDATE_SUCCESS':
            return {
                isError: false,
                isUpdating: false,
                errors: new Errors(null),
            }
        default:
            throw new Error(`Invalid reducer action: ${action.type}`);
    }
};

function useProfileApi() {
    const [state, dispatch] = useReducer(profileReducer, {
        isError: false,
        isUpdating: false,
        errors: new Errors(null),
    });

    const history = useHistory();

    // eslint-disable-next-line
    const { user, setUser } = useContext(UserContext);

    const updateProfile = async (data) => {
        dispatch({ type: 'UPDATE_INIT' });

        try {
            const result = await axios.put('/api/user', data);

            dispatch({ type: 'UPDATE_SUCCESS'});

            setUser(result.data.data);
            localStorage.setItem('user', JSON.stringify(result.data.data));
        } catch (error) {
            console.error(error);

            if (error.response.status === 401 || error.response.status === 419) {
                history.push('/login');
            }

            dispatch({ type: 'UPDATE_ERROR', payload: error.response.data.errors });
        }
    }

    return { state, updateProfile };
}

export default useProfileApi;