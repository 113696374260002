import { selector } from 'recoil';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

const fetchStreak = selector({
    key: 'streakSelector',
    get: async ({ get }) => {
        const result = await axios.get('/api/streak');

        return result.data.data;
    }
});

export default fetchStreak;