import React, { Component } from 'react';
// import { withRouter, Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
// import LoginPage from '../pages/LoginPage';

class LoginErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoggedOut: false
        };

        this.dosmth = this.dosmth.bind(this);
    }

    static getDerivedStateFromError(error) {
        if ( ! error.response) {
            return {
                isLoggedOut: false
            }
        }

        return {
            isLoggedOut: error.response.status === 401 || error.response.status === 419
        };
    }

    // componentDidCatch(error, info) {
    //     console.log(error);
    //     console.log(info);
    // }

    dosmth(event) {
        event.preventDefault();
        console.log('this got clicked');
        this.setState(() => ({
            isLoggedOut: false
        }));

        console.log(this.state.isLoggedOut);

        this.props.history.push('/login');
    }

    render () {
        if (this.state.isLoggedOut) {
            return (
                <button type="button" onClick={ this.dosmth }>Log in</button>
            );
        } else {
            return this.props.children;
        }
    }
}

export default withRouter(LoginErrorBoundary);