import React, { useRef } from 'react';
import useSignupApi from '../hooks/useSignupApi';

function SignupPage() {
    const { state, signup } = useSignupApi();

    const emailInput = useRef();

    const { isLoading, didSignup, isError } = state;

    const handleSubmit = event => {
        event.preventDefault();

        signup(emailInput.current.value);
    }

    return (
        <div className="relative bg-gray-200">
            <div className="mx-auto max-w-screen-xl px-4 py-12 min-h-screen flex justify-center items-center">
                <div className="text-center">
                    <h1 className="text-6xl tracking-tight font-bold mb-8"><span role="img" aria-label="Trophy">🏆</span> Tiny Wins</h1>
                    <h2 className="text-4xl tracking-tight leading-10 font-semibold text-gray-900">Want to start recording your wins?</h2>

                    <p className="mt-3 max-w-md mx-auto text-base text-gray-500">
                        We are currently in beta but if you leave your email we'll get back to you as soon as possible!
                    </p>

                    { ! didSignup ? (
                        <form className="mt-8" action="#" method="POST" onSubmit={ handleSubmit }>
                            <div className="rounded-md shadow-sm">
                                <div>
                                    <input ref={ emailInput } aria-label="Email address" name="email" type="email" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Email address" />
                                </div>
                            </div>

                            { isError && (
                                <p className="mt-2 text-sm text-red-600 text-left" id="text-error">Sorry. Seems that we're having some issues right now. We'll win this in no time ;-)</p>
                            )}

                            <div className="mt-6">
                                <button
                                    disabled={ isLoading }
                                    type="submit"
                                    className={`w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-yellow-500 ${isLoading ? 'cursor-not-allowed' : 'hover:bg-yellow-400 focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600'} transition ease-in-out duration-150`}
                                >
                                    { isLoading ? (
                                        <svg className="w-5 h-5 text-white" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                            <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(3.8934 50 50)">
                                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                            </circle>
                                        </svg>
                                    ) : 'Sign up' }
                                </button>
                            </div>
                        </form>
                    ) : (
                        <p className="mt-3 max-w-md mx-auto text-base text-gray-500">
                            We appreciate your interest! We'll let you know when you can access your account and start winning!
                        </p>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default SignupPage;