import React, { useRef, useEffect } from 'react';
import useWinsApi from '../../hooks/useWinsApi';

function EditWinForm(props) {
    const { win, onCancel } = props;
    const textInput = useRef();

    const { state, editWin } = useWinsApi();
    const { isUpdating, didUpdate, editError } = state;

    useEffect(() => {
        textInput.current.value = win.text;
    }, [win.text, textInput]);

    useEffect(() => {
        if (didUpdate === true) {
            onCancel();
        }
    }, [didUpdate, onCancel]);

    const save = () => {
        win.text = textInput.current.value;
        editWin(win);
    }

    const cancel = () => {
        if ( ! isUpdating) {
            onCancel();
        }
    }

    return (
        <div className="sm:flex w-full">
            <div className="flex-1 mb-4 sm:mb-0">
                <div className="relative rounded-md shadow-sm">
                    <input ref={ textInput } id="text" autoComplete="off" className="form-input bg-gray-200 block w-full sm:text-sm sm:leading-5 focus:border-yellow-300 focus:shadow-outline-yellow text-sm leading-5" />
                </div>

                { editError && (
                    <p className="mt-2 text-sm text-red-600 text-left" id="text-error">{ editError.text[0] }</p>
                )}
            </div>
            <div className="sm:ml-2 sm:w-20 mb-4 sm:mb-0">
                <span className="inline-flex w-full rounded-md shadow-sm">
                    <button
                        type="button"
                        disabled={ isUpdating }
                        onClick={ save }
                        className={`w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-yellow-500 ${isUpdating ? 'cursor-not-allowed' : 'hover:bg-yellow-400 focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600'} transition ease-in-out duration-150`}
                    >
                        { isUpdating ? (
                            <svg className="w-5 h-5 text-white" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(3.8934 50 50)">
                                    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                                </circle>
                            </svg>
                        ) : 'Save' }
                    </button>
                </span>
            </div>
            <div className="sm:ml-2">
                <span className="inline-flex w-full rounded-md shadow-sm">
                    <button
                        type="button"
                        disabled={ isUpdating }
                        onClick={ cancel }
                        className={`w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-yellow-500 bg-white ${isUpdating ? 'cursor-not-allowed' : 'hover:text-yellow-400 focus:outline-none focus:shadow-outline-yellow'}  transition duration-150 ease-in-out`}
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    );
}

export default EditWinForm;