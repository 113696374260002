import { useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

const winsFetchReducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isError: false,
                isSaving: false,
                isLoading: true,
                isDeleting: false,
                isUpdating: false,
                didUpdate: false,
                editError: null,
                saveError: null,
            }
        case 'FETCH_ERROR':
            return {
                ...state,
                isError: true,
                isSaving: false,
                isLoading: false,
                isDeleting: false,
                isUpdating: false,
                didUpdate: false,
                editError: null,
                saveError: null,
            }
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isError: false,
                isSaving: false,
                isLoading: false,
                isDeleting: false,
                isUpdating: false,
                editError: null,
                saveError: null,
                data: action.payload,
            }
        case 'NEW_WIN':
            return {
                ...state,
                isError: false,
                isSaving: false,
                isLoading: false,
                isDeleting: false,
                isUpdating: false,
                didUpdate: false,
                editError: null,
                saveError: null,
                data: [action.payload, ...state.data],
            }
        case 'SAVE_INIT':
            return {
                ...state,
                isError: false,
                isSaving: true,
                isLoading: false,
                isDeleting: false,
                isUpdating: false,
                didUpdate: false,
                editError: null,
                saveError: null,
            }
        case 'SAVE_ERROR':
            return {
                ...state,
                isError: false,
                isSaving: false,
                isLoading: false,
                isDeleting: false,
                isUpdating: false,
                didUpdate: false,
                editError: null,
                saveError: action.payload,
            }
        case 'WIN_UPDATED':
                return {
                    ...state,
                    isError: false,
                    isSaving: false,
                    isLoading: false,
                    isDeleting: false,
                    isUpdating: false,
                    didUpdate: true,
                    editError: null,
                    saveError: null,
                    data: state.data.map((item) => {
                        return item.id === action.payload.id ? action.payload : item;
                    }),
                }
        case 'UPDATE_INIT':
            return {
                ...state,
                isError: false,
                isSaving: false,
                isLoading: false,
                isDeleting: false,
                isUpdating: true,
                didUpdate: false,
                editError: null,
                saveError: null,
            }
        case 'UPDATE_ERROR':
            return {
                ...state,
                isError: false,
                isSaving: false,
                isLoading: false,
                isDeleting: false,
                didUpdate: false,
                isUpdating: false,
                editError: action.payload,
                saveError: null,
            }
        case 'DELETE_INIT':
            return {
                ...state,
                isError: false,
                isSaving: false,
                isLoading: false,
                isDeleting: true,
                isUpdating: false,
                didUpdate: false,
                editError: null,
                saveError: null,
            }
        case 'DELETE_ERROR':
            return {
                ...state,
                isError: true,
                isSaving: false,
                isLoading: false,
                isDeleting: false,
                isUpdating: false,
                didUpdate: false,
                editError: null,
                saveError: null,
            }
        case 'WIN_DELETED':
            return {
                ...state,
                isError: false,
                isSaving: false,
                isLoading: false,
                isDeleting: false,
                isUpdating: false,
                didUpdate: false,
                editError: null,
                saveError: null,
                data: state.data.filter((item) => {
                    return parseInt(item.id, 10) !== parseInt(action.payload.id, 10);
                }),
            }
        default:
            throw new Error(`Invalid reducer action: ${action.type}`);
    }
};

function useWinsApi() {
    const [state, dispatch] = useReducer(winsFetchReducer, {
        isError: false,
        isSaving: false,
        isLoading: false,
        isDeleting: false,
        isUpdating: false,
        didUpdate: false,
        editError: null,
        saveError: null,
        data: [],
    });

    const history = useHistory();

    const fetchWins = async () => {
        dispatch({ type: 'FETCH_INIT' });

        try {
            const result = await axios.get('/api/wins');

            dispatch({ type: 'FETCH_SUCCESS', payload: result.data.data});
        } catch (error) {
            console.error(error);

            if (error.response.status === 401 || error.response.status === 419) {
                history.push('/login');
            }

            dispatch({ type: 'FETCH_ERROR' });
        }
    }

    const fetchLatestWins = async () => {
        dispatch({ type: 'FETCH_INIT' });

        try {
            const result = await axios.get('/api/wins/latest');

            dispatch({ type: 'FETCH_SUCCESS', payload: result.data.data});
        } catch (error) {
            console.error(error);

            if (error.response.status === 401 || error.response.status === 419) {
                history.push('/login');
            }

            dispatch({ type: 'FETCH_ERROR' });
        }
    }

    const addWin = async (win) => {
        dispatch({ type: 'SAVE_INIT' });

        try {
            const result = await axios.post('/api/wins', win);

            dispatch({ type: 'NEW_WIN', payload: result.data.data });
        } catch (error) {
            console.error(error);

            if (error.response.status === 401 || error.response.status === 419) {
                history.push('/login');
            }

            dispatch({ type: 'SAVE_ERROR', payload: error.response.data.errors });
        }
    }

    const deleteWin = async (win) => {
        dispatch({ type: 'DELETE_INIT' });

        try {
            await axios.delete('/api/wins/' + win.id);

            dispatch({ type: 'WIN_DELETED', payload: win });
        } catch (error) {
            console.error(error);

            if (error.response.status === 401 || error.response.status === 419) {
                history.push('/login');
            }

            dispatch({ type: 'DELETE_ERROR' });
        }
    }

    const editWin = async (win) => {
        dispatch({ type: 'UPDATE_INIT' });

        try {
            const result = await axios.put('/api/wins/' + win.id, win);

            dispatch({ type: 'WIN_UPDATED', payload: result.data.data });
        } catch (error) {
            console.error(error);

            if (error.response.status === 401 || error.response.status === 419) {
                history.push('/login');
            }

            dispatch({ type: 'UPDATE_ERROR', payload: error.response.data.errors });
        }
    }

    return { state, fetchWins, fetchLatestWins, addWin, deleteWin, editWin };
}

export default useWinsApi;