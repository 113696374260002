import React, { useState } from 'react';
import WinDropdown from './WinDropdown';
import EditWinForm from './EditWinForm';

function WinItem(props) {
    const { win, onDelete } = props;
    const [editing, setEditing] = useState(false);

    const dropdownItems = [{
        label: 'Edit',
        action: () => {
            setEditing(true);
        }
    }, {
        label: 'Delete',
        action: () => {
            onDelete(win);
        }
    }];

    const onCancel = () => {
        setEditing(false);
    }

    return (
        <div className="-mx-4 sm:-mx-6 px-4 sm:px-6 flex items-center justify-between py-2 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
            { editing ? (
                <EditWinForm win={ win } onCancel={ onCancel }></EditWinForm>
            ) : (
                <>
                    <div className="mr-6 flex items-center text-sm leading-5 text-gray-500">
                        { win.text }
                    </div>
                    <WinDropdown options={ dropdownItems }></WinDropdown>
                </>
            )}
        </div>
    )
}

export default WinItem;